import React from "react";
import { Box, Typography } from "@mui/material";
import profileImage from "../../assets/home/profileImage.png";

const CardSectionMobile = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "278.53px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      {/* Circle Row */}
      <Box
        sx={{
          background:
            "linear-gradient(247.72deg, #5FBA99 3.39%, #13FFA9 53.48%, #92FFD7 100%)",
          width: "100%",
          height: "219.05px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          zIndex: "1",
          flexDirection: "column",
          opacity: "80%",
        }}
      >
        {/* Center Large Circle */}
        <Box
          sx={{
            width: "169px",
            height: "169px",
            borderRadius: "50%",
            // background: '#5FBA99',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translateY(-10%)",
            zIndex: 1,
          }}
        >
          <Box
            component="img"
            src={profileImage}
            alt="Center Circle"
            sx={{
              width: "138.1px",
              height: "138.1px",
              objectFit: "cover",
              borderRadius: "50%",
              boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
            }}
          />
        </Box>

        <Box
          sx={{
            color: "#101011",
            mt: { xs: "0px", sm: "35px", md: "50px" },
            textAlign: "center",
            width: { xs: "85%", sm: "65%", md: "54%" },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#101011",
              fontWeight: "800",
              margin: 0,
              fontSize: { xs: "16px", sm: "22px", md: "24px" },
              lineHeight: { xs: "19.36px", sm: "28px", md: "29.05px" },
            }}
          >
            SUJIT PATIL
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#101011",
              fontWeight: "600",
              fontSize: { xs: "10px", sm: "15px", md: "16px" },
              lineHeight: { xs: "12.1px", sm: "19.38px", md: "19.38px" },
            }}
          >
            MAHARASHTRA
          </Typography>
          <Box
            sx={{
              marginTop: { xs: "0px", sm: "50px", md: "45px" },
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              align="center"
              sx={{
                width: "100%",
                maxWidth: "423.54px",
                height: "95px",
                color: "#000000",
                fontWeight: "400",
                fontSize: { xs: "10px", sm: "15px", md: "16px" },
                lineHeight: { xs: "12.1px", sm: "18px", md: "19.38px" },
              }}
            >
              Rest assured, we are regulated by the Dubai Financial Services
              Authority (DFSA). Our commitment to transparency means we
              thoroughly outline our fees and principles for the highest level
              of openness and security.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardSectionMobile;
