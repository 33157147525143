import React from "react";
import { Box, Button, Typography } from "@mui/material";
import graphMobileImage from "../../assets/home/graphMobileImage.png";
import mobileHandImage from "../../assets/home/mobileHandImage.png";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";

const HomeSectionMobile = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "700px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "relative",
        overflow: "hidden",
        marginBottom: {
          xs: "30px",
          sm: "50px",
          md: "66px",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "10%",
          display: "flex",
          flexDirection: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: {
              xs: "8%",
              sm: "3%",
              md: "5.5%",
            },
            transform: {
              xs: "translateY(-41%)",
              sm: "translateY(0%)",
              md: "translateY(2%)",
            },
            width: {
              xs: "100%",
              md: "100%",
            },
            maxWidth: "335.74px",
            height: "410.22px",
            backgroundImage: `url(${mobileHandImage})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>

        <Box
          sx={{
            width: "100%",
            height: { xs: "139px", sm: "139px", md: "250px" },
            backgroundImage: `url(${graphMobileImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: "1",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          textAlign: { xs: "center", md: "left" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: { xs: "0px 30px", md: "0px 40px" },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#23ECA2",
            fontWeight: "700",
            fontSize: { xs: "16px", sm: "24px", md: "36px" },
            lineHeight: { xs: "30px", sm: "24px", md: "50px" },
          }}
        >
          JOIN OUR COMMUNITY
          <br />
          MANY INVESTORS AND START YOUR JOURNEY TOWARDS LONG-TERM WEALTH
          CREATION.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            justifyContent: { xs: "center", md: "flex-start" },
            gap: "15px",
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2DDEF8",
                color: "#000000",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "6px",
                p: "10px 20px",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2DDEF8",
              color: "#000000",
              fontSize: "16px",
              fontWeight: "600",
              borderRadius: "6px",
              padding: "10px 20px",
            }}
          >
            INVEST NOW
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSectionMobile;
