import React from "react";
import { Box, Typography } from "@mui/material";
import profileImage from "../../assets/home/profileImage.png";

const CardSection = () => {
  return (
    <Box
      sx={{
        // background: "linear-gradient(to bottom, #59c988, #83eaf1)",
        background:
          "linear-gradient(247.72deg, #5FBA99 3.39%, #13FFA9 53.48%, #92FFD7 100%)",
        width: "100%",
        height: { xs: "420px", sm: "550px", md: "602.52px" },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        marginTop: "80px",
        opacity: "80%",
      }}
    >
      {/* Circle Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Left Two Circles */}
        {[0, 1].map((index) => (
          <Box
            key={index}
            sx={{
              width: { xs: "70px", sm: "100px", md: "128px" },
              height: { xs: "70px", sm: "100px", md: "128px" },
              backgroundColor: "white",
              borderRadius: "50%",
              overflow: "hidden",
              boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={profileImage}
              alt={`Circle image ${index + 1}`}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ))}

        {/* Center Large Circle */}
        <Box
          sx={{
            width: { xs: "120px", sm: "150px", md: "219px" },
            height: { xs: "120px", sm: "150px", md: "219px" },
            backgroundColor: "white",
            borderRadius: "50%",
            overflow: "hidden",
            boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translateY(20%)",
            zIndex: 1,
          }}
        >
          <Box
            component="img"
            src={profileImage}
            alt="Center Circle"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Right Two Circles */}
        {[3, 4].map((index) => (
          <Box
            key={index}
            sx={{
              width: { xs: "70px", sm: "100px", md: "128px" },
              height: { xs: "70px", sm: "100px", md: "128px" },
              backgroundColor: "white",
              borderRadius: "50%",
              overflow: "hidden",
              boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={profileImage}
              alt={`Circle image ${index + 1}`}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ))}
      </Box>

      {/* Info Box */}
      <Box
        sx={{
          backgroundColor: "#AAD9C8",
          width: { xs: "85%", sm: "65%", md: "54%" },
          maxWidth: "823px",
          height: { xs: "200px", sm: "280px", md: "281.24px" },
          textAlign: "center",
          padding: "20px",
          borderRadius: "39px",
        }}
      >
        <Box
          sx={{
            color: "#101011",
            mt: { xs: "20px", sm: "35px", md: "50px" },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#101011",
              fontWeight: "800",
              margin: 0,
              fontSize: { xs: "20px", sm: "22px", md: "24px" },
              lineHeight: { xs: "27px", sm: "28px", md: "29.05px" },
            }}
          >
            SUJIT PATIL
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#101011",
              fontWeight: "600",
              fontSize: { xs: "14px", sm: "15px", md: "16px" },
              lineHeight: { xs: "19.38px", sm: "19.38px", md: "19.38px" },
            }}
          >
            MAHARASHTRA
          </Typography>
          <Box
            sx={{
              marginTop:  { xs: "25px", sm: "50px", md: "45px" },
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              align="center"
              sx={{
                width: "100%",
                maxWidth: "426.65px",
                height: "95px",
                color: "#000000",
                fontWeight: "500",
                fontSize: { xs: "14px", sm: "15px", md: "16px" },
                lineHeight: { xs: "17px", sm: "18px", md: "19.38px" },
              }}
            >
              Rest assured, we are regulated by the Dubai Financial Services
              Authority (DFSA). Our commitment to transparency means we
              thoroughly outline our fees and principles for the highest level
              of openness and <br />
              security.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardSection;
