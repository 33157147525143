import React from "react";
import { Box, Container } from "@mui/material";
import Header from "../src/components/header/Header";
import Footer from "./components/footer/Footer";
import HomePage from "./pages/Home/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InvestPage from "./pages/Invest/invest";
import AboutPage from "./pages/About/about";
import PlansPage from "./pages/Plans/plans";
import ContactPage from "./pages/Contact/contact";

function App() {
  return (
    <Router>
      <Container
        maxWidth={false}
        disableGutters
        // sx={{
        //   backgroundColor: "#101011", // Background color applied from previous CSS
        //   minHeight: "100vh", // Full height of viewport
        //   display: "flex",
        //   flexDirection: "column", // Ensure header, content, and footer are stacked vertically
        // }}
      >
        <Header />
        {/* <HomePage /> */}
        <Box component="main">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/invest" element={<InvestPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/plans" element={<PlansPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </Box>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;

// import React from 'react';
// import Header from './components/Header';
// // import MainContent from './components/MainContent';
// import Footer from './components/Footer';
// import './App.css';
// import HomePage from '../src/pages/Home/index'

// function App() {
//   return (
//     <div className="App">
//       <Header />
//       {/* <MainContent /> */}
//       <HomePage/>
//       <Footer />
//     </div>
//   );
// }

// export default App;
