import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CardSection from "../../components/cardSection/CardSection";
import frontIMage from "../../assets/plan/frontImage.png";
import frontImageMobile from "../../assets/plan/frontImageMobile.png";
import bgImage from "../../assets/plan/bgimage.png";
import CardSectionMobile from "../../components/cardSection/CardSectionMobile";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";

const PlansPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
      }}
    >
      <Box
        sx={{
          marginBottom: "50px",
          width: "100%",
          height: { xs: "652.72px", md: "588px" },
          backgroundImage: {
            xs: `url(${frontImageMobile})`,
            sm: `url(${frontIMage})`,
            md: `url(${frontIMage})`,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Typography
        variant="h4"
        sx={{
          textAlign: { xs: "center", sm: "center", md: "left" },
          color: "#FFFFFF",
          fontWeight: "600",
          fontSize: { xs: "20px", sm: "30px", md: "34px" },
          paddingLeft: { xs: "0px", sm: "0%", md: "14%" },
        }}
      >
        Our packages
      </Typography>

      <Box
        sx={{
          textAlign: "center",
          mb: { xs: "40px", sm: "70px", md: "100px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "170px",
          padding: { xs: "50px 50px", md: "50px 100px" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
          rowGap: "50px",
          // width: { xs: "100%", sm: "100%", md: "36%" },
        }}
      >
        <Box
          sx={{
            width: { xs: "283.64px", sm: "100%", md: "36%" },
            maxWidth: "502px",
            height: { xs: "342.6px", sm: "645px", md: "645px" },
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
          }}
        ></Box>

        <Box
          sx={{
            width: { xs: "283.64px", sm: "100%", md: "36%" },
            maxWidth: "502px",
            height: { xs: "342.6px", sm: "645px", md: "645px" },
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
          }}
        ></Box>
      </Box>

      {isSmallScreen ? (
        <Box
          sx={{
            width: { xs: "100%" },
            // height: { xs: "100%", sm: "100%", md: "750px" },
            height: "217.83px",
            mb: { xs: "80px", sm: "100px", md: "120px" },
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: "0.5",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            paddingBottom: "10px",
            zIndex: "0",
            // background: "#00000099"
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000000",
                color: "#2DDEF8",
                fontSize: "10px",
                fontWeight: "600",
                borderRadius: "6px",
                zIndex: "100000",
                p: "15px 15px",
                lineHeight: "12.1px",
                // transform: "translateY(-10%)",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
        </Box>
      ) : (
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%" },
            // height: { xs: "100%", sm: "100%", md: "750px" },
            height: "750px",
            mb: { xs: "50px", sm: "100px", md: "120px" },
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: "0.5",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            paddingBottom: "30px",
            zIndex: "0",
            // background: "#00000099"
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000000",
                color: "#2DDEF8",
                fontSize: "20px",
                fontWeight: "600",
                borderRadius: "6px",
                zIndex: "100000",
                p: "15px 25px",
                // transform: "translateY(-10%)",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
        </Box>
      )}

      <Box
        sx={{
          marginBottom: { xs: "20px", sm: "40px", md: "40px" },
        }}
      >
        {isSmallScreen ? <CardSectionMobile /> : <CardSection />}
      </Box>
    </Box>
  );
};

export default PlansPage;
