import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import fileImage1 from "../../assets/invest/file1.png";
import fileImage2 from "../../assets/invest/file2.png";
import fileImage3 from "../../assets/invest/file3.png";
import fileImage4 from "../../assets/invest/file4.png";

const OverServicesMobile = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        textAlign: "center",
        marginBottom: "100px",
        marginTop: "0px",
        padding: { xs: "0px 80px", md: "0px 200px" },
      }}
    >
      <Typography
        variant="h4"
        align="center"
        fontWeight={"600"}
        fontSize={{ xs: "14px", sm: "20px", md: "24px" }}
        gutterBottom
        sx={{
          textDecoration: "underline",
          textDecorationColor: "#2DDEF8",
          textUnderlineOffset: "15px",
          textDecorationThickness: "5px",
        }}
      >
        Our Services
      </Typography>

      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: { xs: "20px", md: "40px" },
        }}
      >
        {/* Image Box */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              // paddingLeft: "10px",
            }}
          >
            <Box
              component="img"
              src={fileImage1}
              alt="Illustration"
              sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: { xs: "80%", sm: "50%", md: "369.15px" },
                height: "auto",
              }}
            />
          </Box>
        </Grid>

        {/* Text Content */}
        <Grid item xs={12} md={5}>
          <Box align={isSmallScreen ? "center" : "left"}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "14px", sm: "20px", md: "24px" },
                color: "#FFFFFF",
                lineHeight: { xs: "16.94px", sm: "22px", md: "29.05px" },
                fontWeight: "600",
              }}
            >
              Market-Leading Returns
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                color: "#FFFFFF",
                lineHeight: { xs: "14.52px", sm: "20px", md: "24.02px" },
                fontWeight: "500",
                marginTop: "16px",
              }}
            >
              Build your own diversified real estate portfolio to unlock monthly
              rental income and long-term capital appreciation, potentially
              yielding total returns exceeding 40%*
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: { xs: "20px", md: "40px" },
        }}
      >
        {/* Text Content */}
        <Grid item xs={12} md={5}>
          <Box align={isSmallScreen ? "center" : "left"}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "14px", sm: "20px", md: "24px" },
                color: "#FFFFFF",
                lineHeight: { xs: "16.94px", sm: "22px", md: "29.05px" },
                fontWeight: "600",
              }}
            >
              Exclusive Community
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                color: "#FFFFFF",
                lineHeight: { xs: "14.52px", sm: "20px", md: "24.02px" },
                fontWeight: "500",
                marginTop: "16px",
              }}
            >
              People who value the same ideas of <br />
              wellness, privacy and understand luxury.
            </Typography>
          </Box>
        </Grid>
        {/* Image Box */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              // paddingLeft: "10px",
            }}
          >
            <Box
              component="img"
              src={fileImage2}
              alt="Illustration"
              sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: { xs: "80%", sm: "50%", md: "369.15px" },
                height: "auto",
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: { xs: "20px", md: "40px" },
        }}
      >
        {/* Image Box */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              // paddingLeft: "10px",
            }}
          >
            <Box
              component="img"
              src={fileImage3}
              alt="Illustration"
              sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: { xs: "80%", sm: "50%", md: "369.15px" },
                height: "auto",
              }}
            />
          </Box>
        </Grid>

        {/* Text Content */}
        <Grid item xs={12} md={5}>
          <Box align={isSmallScreen ? "center" : "left"}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "14px", sm: "20px", md: "24px" },
                color: "#FFFFFF",
                lineHeight: { xs: "16.94px", sm: "22px", md: "29.05px" },
                fontWeight: "600",
              }}
            >
              All-Digital Experience
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                color: "#FFFFFF",
                lineHeight: { xs: "14.52px", sm: "20px", md: "24.02px" },
                fontWeight: "500",
                marginTop: "16px",
              }}
            >
              Enjoy an entirely digital property investing experience. No more
              running around, no tedious paperwork – just seamless, hassle-free
              investing from anywhere, anytime through our mobile app and web
              platform.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          marginTop: { xs: "20px", md: "40px" },
        }}
      >
        {/* Text Content */}
        <Grid item xs={12} md={5}>
          <Box align={isSmallScreen ? "center" : "left"}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "14px", sm: "20px", md: "24px" },
                color: "#FFFFFF",
                lineHeight: { xs: "16.94px", sm: "22px", md: "29.05px" },
                fontWeight: "600",
              }}
            >
              Security & Transparency
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                color: "#FFFFFF",
                lineHeight: { xs: "14.52px", sm: "20px", md: "24.02px" },
                fontWeight: "500",
                marginTop: "16px",
              }}
            >
              Rest assured, we are regulated by the Dubai Financial Services
              Authority (DFSA). Our commitment to transparency means we
              thoroughly outline our fees and principles for the highest level
              of openness and security.
            </Typography>
          </Box>
        </Grid>
        {/* Image Box */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              // paddingLeft: "10px",
            }}
          >
            <Box
              component="img"
              src={fileImage4}
              alt="Illustration"
              sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: { xs: "80%", sm: "50%", md: "369.15px" },
                height: "auto",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverServicesMobile;
