import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import iconImage from "../../assets/home/icon.png";

const Header = () => {
  // const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // const bgColor = ["about", "plan", "contact"].some((route) =>
  //   location.pathname.includes(route)
  // )
  //   ? "#FFFFFF"
  //   : "#101011";

  return (
    <>
      <AppBar
        // position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          // backgroundColor: bgColor,
          padding: { xs: "0px", md: "10px 15px" },
        }}
      >
        <Toolbar>
          {/* <Typography
            variant="h6"
            sx={{ flexGrow: 1, fontSize: "24px", fontWeight: "600" }}
          >
            LOGO
          </Typography> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "block", md: "block" },
              justifyContent: "left",
              paddingTopTop: "20px",
            }}
          >
            <Box
              sx={{
                width: { xs: "27.05px", sm: "50.05px", md: "60px" },
                height: { xs: "28.03px", sm: "50.03px", md: "60px" },
                backgroundImage: `url(${iconImage})`,
                backgroundSize: { xs: "contain", sm: "contain" },
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Box>

          {/* For larger screens */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: { xs: "none", md: "space-between" },
              width: { sm: "55%", md: "55%" },
              maxWidth: "55%",
              // gap:'45px'
            }}
          >
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={{ gap: "50px", fontSize: "16px", fontWeight: "600" }}
            >
              HOME
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/invest"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              INVEST
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/about"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              ABOUT US
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/plans"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              PLANS
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/contact"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              CONTACT US
            </Button>
          </Box>

          {/* For smaller screens */}
          <IconButton
            color="inherit"
            edge="end"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button component={Link} to="/">
              <ListItemText primary="HOME" />
            </ListItem>
            <ListItem button component={Link} to="/invest">
              <ListItemText primary="INVEST" />
            </ListItem>
            <ListItem button component={Link} to="/about">
              <ListItemText primary="ABOUT US" />
            </ListItem>
            <ListItem button component={Link} to="/plans">
              <ListItemText primary="PLANS" />
            </ListItem>
            <ListItem button component={Link} to="/contact">
              <ListItemText primary="CONTACT US" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
