import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
// import { useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import circleImage1 from "../../assets/contentSection/image 1.png";
import circleImage2 from "../../assets/contentSection/image 2.png";
import circleImage3 from "../../assets/contentSection/image 3.png";
import circleImage4 from "../../assets/contentSection/image 4.png";

// Custom button style to mimic the gradient effect and required dimensions
const GradientButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #101011 0%, #21EDA2 100%)",
  color: "#FFFFFF",
  borderRadius: "54.5px",
  padding: "0px 10px",
  width: "100%",
  maxWidth: "383.58px",
  height: "42.03px",
  justifyContent: "end",
  columnGap: "10px",
  display: "flex",
  alignItems: "center",
  opacity: 1,
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    background: "linear-gradient(90deg, #101011 0%, #1FBF8A 100%)",
  },
  // Responsive styles using theme breakpoints
  [theme.breakpoints.down("md")]: {
    maxWidth: "700px",
    height: "79px",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "550px",
    height: "70px",
  },
}));

const Circle = styled(Box)(({ theme, imageUrl }) => ({
  width: "35.64px",
  height: "35.04px",
  backgroundColor: "#AAEED6",
  borderRadius: "50%",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "21.67px 21.67px",
  //? backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  // Responsive styles using theme breakpoints
  [theme.breakpoints.down("md")]: {
    width: "67px",
    height: "67px",
    backgroundSize: "40.74px 40.74px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    backgroundSize: "30.74px 30.74px",
  },
}));

const ContentSectionMobille = () => {
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        padding: { xs: "0px 20px" },
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        align="center"
        fontWeight={"600"}
        fontSize={{ xs: "14px", sm: "24px", md: "26px" }}
        gutterBottom
        sx={{
          textDecoration: "underline",
          textDecorationColor: "#2DDEF8",
          textUnderlineOffset: { xs: "10px", md: "15px" },
          textDecorationThickness: { xs: "3px", md: "5px" },
        }}
      >
        Why Should You Choose Triangle
      </Typography>

      {/* Main Section */}
      <Grid container spacing={4} alignItems="center" marginTop={"10px"}>
        {/* Left: Gradient Buttons */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
              alignItems: "center",
              //// maxWidth: "721px",
            }}
          >
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  //// height:"70px",
                  maxWidth: "498px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "right",
                  textTransform: "none",
                }}
              >
                One single destination for your entire <br /> investment needs
              </Typography>
              <Circle imageUrl={circleImage1} />
            </GradientButton>

            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "70%", md: "90%" },
                  //// height:"58px",
                  maxWidth: "373.37px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "right",
                  textTransform: "none",
                }}
              >
                Get your own Personal <br />
                Investment Coach
              </Typography>
              <Circle imageUrl={circleImage2} />
            </GradientButton>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  //? height:"58px",
                  maxWidth: "356.69px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "right",
                  textTransform: "none",
                }}
              >
                World class in-house Research <br />
                Team
              </Typography>
              <Circle imageUrl={circleImage3} />
            </GradientButton>

            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "50%", md: "50%" },
                  //? height:"58px",
                  maxWidth: "266.04px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "right",
                  textTransform: "none",
                }}
              >
                Most trustable platform
              </Typography>
              <Circle imageUrl={circleImage4} />
            </GradientButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentSectionMobille;
