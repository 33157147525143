import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import GroupInfo from "../../assets/home/GroupInfo.png";

// Custom styled components for buttons with gradient background
const GradientButton = styled(Button)(({ theme }) => ({
  background: "radial-gradient(50% 50% at 50% 50%, #8EFED5 0%, #1ECB8C 100%)",
  color: "#101011",
  borderRadius: "50px",
  padding: "10px 20px",
  // width: "100%",
  // maxWidth: "450px",
  height: "35.13px",
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  transition: "background 0.3s ease-in-out",
  // overflow: "hidden",
  "&:hover": {
    background: "linear-gradient(90deg, #018354 0%, #28FFC8 100%)",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    maxWidth: "450px",
    height: "70px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "450px",
    height: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    maxWidth: "450px",
    height: "35px",
  },
}));

// Circle with number inside
const StepCircle = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "66px",
  backgroundColor: "#7CE5C0",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#018354", // Text color inside the circle
  fontSize: "50px",
  fontWeight: "700",
  marginLeft: "-65px", // Half of the width of the circle to overlap the button
  position: "relative",

  zIndex: 2,
  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    fontSize: "18px",
    marginLeft: "-45px",
    // marginRight: "10px",
  },
}));

// Circle background for dark shadow effect
const StepCircle1 = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "66px",
  backgroundColor: "#101011",
  borderRadius: "50%",
  // marginLeft: "-40px", // Half of the width of the circle to overlap the button
  // marginRight: "-20px", // Gap between circle and text

  position: "absolute",
  left: "-20px",
  zIndex: 1,

  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    // marginLeft: "-15px",
    // marginRight: "10px",

    left: "-12px",
  },
}));

const StepText = styled("span")(({ theme }) => ({
  fontWeight: "600",
  color: "#000000", // Text color inside button
  marginLeft: "20px", // Ensures text is positioned after the circle
 
}));

// Vertical line between circles
const StepConnector = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "66px", // Aligns with bottom of circle
  left: "-11px", // Centered with the circle
  width: "2px",
  height: "40px", // Height between circles
  backgroundColor: "#7CE5C0",
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    top: "40px",
    left: "-10px",
    height: "50px",
  },
}));

const InfoSectionMobile = () => {
  return (
    <Box
      sx={{ padding: { xs: "0px 20px", md: "0px 100px" } }}
      marginTop={"50px"}
      marginBottom={"40px"}
    >
      <Typography
        variant="h5"
        align="center"
        fontWeight={"600"}
        fontSize={{ xs: "14px", sm: "24px", md: "26px" }}
        lineHeight={{ xs: "16.94px", sm: " 25px", md: "26px" }}
        gutterBottom
      >
        OUR 10 MIN - PROMISEmo
      </Typography>
      <Typography
        variant="body2"
        align="center"
        fontWeight={"600"}
        fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
        lineHeight={{ xs: "14.52px", sm: " 16.94px", md: "16.94px" }}
        gutterBottom
        sx={{
          textDecoration: "underline",
          textDecorationColor: "#2DDEF8",
          textUnderlineOffset: { xs: "10px", md: "15px" },
          textDecorationThickness: { xs: "3px", md: "5px" },
        }}
      >
        GET YOUR ACCOUNT SETUP SUPER FAST WITH US
      </Typography>

      <Grid
        container
        spacing={5}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          marginTop: { xs: "0px", md: "0px" },
        }}
      >
        {/* Left Side: Image */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              component="img"
              src={GroupInfo}
              alt="Illustration"
              sx={{
                width: "100%",
                maxWidth: { xs: "243.98px", sm: "494.19px", md: "494.19px" },
                height: { xs: "241.98px", sm: "440.55px", md: "440.55px" },
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: { xs: "20px", sm: "32px", md: "32px" },
              paddingLeft: { xs: "24px", sm: "50px", md: "" },
            }}
          >
            {/* Button with steps */}
            {[
              { step: 1, label: "PAN Details" },
              { step: 2, label: "KYC Details" },
              { step: 3, label: "Add your nominee" },
              { step: 4, label: "Link your bank" },
              { step: 5, label: "Address" },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: { xs: "65%", sm: "70%", md: "" },
                }}
              >
                <GradientButton>
                  <StepCircle>{item.step}</StepCircle>
                  <StepCircle1 />
                  <StepText
                    sx={{
                      fontWeight: "600",
                      fontSize: { xs: "12px", sm: "20px", md: "26px" },
                      marginLeft: { xs: "20px", sm: "40px", md: "40px" },
                    }}
                  >
                    {item.label}
                  </StepText>
                </GradientButton>
                {index < 4 && <StepConnector />}{" "}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoSectionMobile;
