import React from "react";
import { Box, Button, Typography } from "@mui/material";
import phoneImage from "../../assets/about/phoneScreen.png";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";
// import splashScreen from "../../assets/about/splashSreen.png";

const MobileSectionWeb = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "656.8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "352.56px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:
            "linear-gradient(90deg, #1ECB8B 0%, rgba(152, 241, 208, 0.83902) 100%)",
          position: "relative",
          //? flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* Left side text  */}
        <Typography
          variant="h4"
          sx={{
            color: "#000000",
            fontWeight: "600",
            fontSize: { xs: "35px", sm: "50px", md: "64px" },
            maxWidth: { xs: "30%", sm: "30%", md: "30%" },
            textAlign: { xs: "left", md: "left" },
            lineHeight: { xs: "40px", sm: "50px", md: "60.45px" },
            paddingLeft: { xs: "40px", sm: "20px", md: "92px" },
            //? mb: { xs: 3, md: 0 },
          }}
        >
          Ready to turn dreams <br />
          into reality?
        </Typography>{" "}
        <Box
          sx={{
            width: "20%",
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                width: { xs: "150px", sm: "200px", md: "230px" },
                backgroundColor: "#000000",
                color: "#2DDEF8",
                fontSize: { xs: "14px", sm: "16px", md: "20px" },
                fontWeight: "600",
                borderRadius: "6px",
                p: "15px 25px",
                position: "relative",
                left: { xs: "50px", sm: "40px", md: "75px" },
                top: "100px",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
        </Box>
        {/* Phone image positioned partially above the green background  */}
        <Box
          component="img"
          src={phoneImage}
          alt="Hand holding phone"
          sx={{
            position: "relative",
            zIndex: "1",
            width: { xs: "50%", sm: "35%", md: "40%" },
            height: "656.8px",
            maxWidth: "601px", // Limit the max width for larger screens
            paddingRight: { xs: "0px", sm: "0px", md: "50px" },
            transform: "translateY(-23%)",
            // height: { xs: "auto", md: "656.8px" },
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MobileSectionWeb;
